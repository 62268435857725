import React from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Expo, Power2, Power3, Power4 } from "gsap";
import { useRef, useEffect, useState } from "react";

gsap.registerPlugin(TweenMax, TimelineMax, Expo, Power2, Power3, Power4);

export default function Video() {
  const [isHidden, setHide] = useState(true);
  let screen = useRef(null);
  let body = useRef(null);
  let img = useRef(null);

  const data = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    var tl = new TimelineMax();
    tl.to(screen, {
      duration: 1.2,
      height: "100%",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 1,
      top: "100%",
      ease: Power3.easeInOut,
      delay: 0.3,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(1);
    TweenMax.to(img, 0.5, {
      ease: Expo.easeIn,
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    }).delay(1.4);
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });

  const handleClick = (e) => {
    setHide(false);
  };

  return (
    <React.Fragment>
      <div className="load-container">
        <div className="load-screen3" ref={(el) => (screen = el)}></div>
      </div>
      <div data-barba="container" className="Video">
        <div ref={(el) => (body = el)} className="Headd">
          <div className="screen5-background">
            <div className="screen5-content">
              <div className="screen5-title">
                <div className="screen5-title-inner">
                  <div>Mon en af dine videoer er årets mest afspillede?</div>
                </div>
              </div>
              <div
                className="videoscreen-image"
                style={{ backgroundImage: `url(${data.videoThumb})` }}
              >
                {isHidden ? (
                  <>
                    <div
                      ref={(el) => (img = el)}
                      className="videoscreen-blur"
                    />
                    <div onClick={(e) => handleClick(e)} title="reveal video">
                      <div className="videoscreen-button">AFSLØR</div>
                    </div>
                  </>
                ) : (
                  <>
                    <a
                      href={data.video}
                      target="_blank"
                      rel="noreferrer"
                      className="video-link-wrapper"
                    >
                      <div className="video-link">
                        <div className="video-playbutton">SE ></div>
                      </div>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="screen5-button-next">
            <NavLink to="/end" className="button">
              Næste
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
