import React from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";

import { ReactComponent as TwinkleBlue } from "../images/twinkleBlue.svg";
import { ReactComponent as HeartBlue } from "../images/heartRed.svg";
import { ReactComponent as EmentoLogo } from "../images/ementoLogo.svg";

gsap.registerPlugin(TweenMax, TimelineMax, Power3, Power4);

export default function End() {
  let screen = useRef(null);
  let body = useRef(null);

  const data = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    var tl = new TimelineMax();
    tl.to(screen, {
      duration: 0.6,
      width: "100%",
      left: "0%",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 0.8,
      left: "100%",
      ease: Power3.easeInOut,
      delay: 0.1,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(1);
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <React.Fragment>
      <div className="load-container">
        <div className="end-screen" ref={(el) => (screen = el)}></div>
      </div>
      <div data-barba="container" className="End">
        <div ref={(el) => (body = el)} className="Headd">
          <div className="endscreen-background">
            <div className="screen1-twinkle" style={{ top: "4%", left: "18%" }}>
              <TwinkleBlue className="svg-twinkle" />
            </div>
            <div
              className="screen1-twinkle-small"
              style={{ bottom: "10%", left: "30%" }}
            >
              <div className="scaler">
                <TwinkleBlue className="svg-twinkle" />
              </div>
            </div>
            <div
              className="screen1-twinkle-small"
              style={{ right: "40%", top: "15%" }}
            >
              <TwinkleBlue className="svg-twinkle" />
            </div>
            <div
              className="screen1-twinkle"
              style={{ right: "8%", bottom: "20%" }}
            >
              <TwinkleBlue className="svg-twinkle" />
            </div>
            <div
              className="screen1-twinkle-small"
              style={{ left: "5%", top: "20%" }}
            >
              <TwinkleBlue className="svg-twinkle" />
            </div>
            <div
              className="screen1-twinkle"
              style={{ left: "8%", bottom: "40%" }}
            >
              <TwinkleBlue className="svg-twinkle" />
            </div>
            <div className="screen1-heart" style={{ right: "7%", top: "5%" }}>
              <HeartBlue className="svg-heart" />
            </div>
            <div className="screen1-content-wrapper">
              <div className="screen1-title-wrapper">
                <div className="endscreen-title">{data.endMessage01}</div>
                <div className="endscreen-subtitle">{data.endMessage02}</div>
                <EmentoLogo className="endscreen-emento" />
              </div>
            </div>
            <div className="end-button">
              <NavLink to={"/" + data.id} className="button">
                Se forfra
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
