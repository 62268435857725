import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Patients from "./components/Patients";
import Messages from "./components/Messages";
import Tasks from "./components/Tasks";
import Video from "./components/Video";
import End from "./components/End";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import "./App.css";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Droid Sans", "Chilanka"],
      },
    });
    const body = document.querySelector("body");
    body.setAttribute("data-barba", "wrapper");
  }, []);

  return (
    <>
      <Helmet>
        <title>Emento Wrapped 2022</title>
      </Helmet>
      <div className="App" data-barba="container">
        <Router>
          <Routes>
            <Route path="/:id" exact element={<Home />} />
            <Route path="/patients" exact element={<Patients />} />
            <Route path="/messages" exact element={<Messages />} />
            <Route path="/tasks" exact element={<Tasks />} />
            <Route path="/video" exact element={<Video />} />
            <Route path="/end" exact element={<End />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
