import React from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";
import jsondata from "../data/data.json";

import { ReactComponent as TwinkleBlue } from "../images/twinkleBlue.svg";
import { ReactComponent as HeartBlue } from "../images/heartBlue.svg";

gsap.registerPlugin(TweenMax, TimelineMax, Power3, Power4);

export default function Home() {
  let screen = useRef(null);
  let body = useRef(null);

  const params = useParams();
  const clientId = params.id;

  var clientData = jsondata.clients.filter(function (item) {
    return item.id === clientId;
  });
  console.log(clientId);
  console.log(clientData[0]);

  localStorage.setItem("data", JSON.stringify(clientData[0]));

  useEffect(() => {
    var tl = new TimelineMax();
    tl.to(screen, {
      duration: 0.6,
      width: "100%",
      left: "0%",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 0.8,
      left: "100%",
      ease: Power3.easeInOut,
      delay: 0.1,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(1);
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <React.Fragment>
      <div className="load-container">
        <div className="load-screen" ref={(el) => (screen = el)}></div>
      </div>
      <div data-barba="container" className="Home">
        <div ref={(el) => (body = el)} className="Headd">
          <div
            className="screen1-twinkle"
            style={{ top: "20vh", left: "15vw" }}
          >
            <TwinkleBlue className="svg-twinkle" />
          </div>
          <div
            className="screen1-twinkle-small"
            style={{ bottom: "20%", left: "18%" }}
          >
            <TwinkleBlue className="svg-twinkle" />
          </div>
          <div
            className="screen1-twinkle-small"
            style={{ right: "40%", top: "12%" }}
          >
            <TwinkleBlue className="svg-twinkle" />
          </div>
          <div
            className="screen1-twinkle"
            style={{ right: "17%", bottom: "14%" }}
          >
            <TwinkleBlue className="svg-twinkle" />
          </div>
          <div className="screen1-heart" style={{ right: "12%", top: "14%" }}>
            <HeartBlue className="svg-heart" />
          </div>
          <div className="screen1-content-wrapper">
            <div className="screen1-title-wrapper">
              <div className="screen1-pretitle">Kære</div>
              <div className="screen1-title">{clientData[0].name}</div>
              <div className="screen1-subtitle">
                Jeres 2022 årsoverblik er klar!
              </div>
              <NavLink to="/patients" className="button">
                Hop ind og kig!
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile"><div>Årsoverblikket er desværre ikke tilgængelig på mobil</div></div>
    </React.Fragment>
  );
}
