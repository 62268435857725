import React from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Expo, Power2, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";

import { ReactComponent as MessageRed } from "../images/messageRed.svg";
import { ReactComponent as HeartRed } from "../images/heartRed.svg";
import { ReactComponent as ThankYou } from "../images/speechBubbleBlue.svg";

gsap.registerPlugin(TweenMax, TimelineMax, Expo, Power2, Power3, Power4);

export default function Messages() {
  let screen = useRef(null);
  let body = useRef(null);
  let rightside = useRef(null);
  let leftside = useRef(null);
  let leftback = useRef(null);

  const data = JSON.parse(localStorage.getItem("data"));

  var numberFormatter = new Intl.NumberFormat();
  const formattedNumber = numberFormatter.format(data.messages);
  const formattedThanks = numberFormatter.format(data.thanks);

  useEffect(() => {
    var tl = new TimelineMax();
    //console.log(screen);
    tl.to(screen.children, {
      duration: 0.4,
      scaleY: 1,
      transformOrigin: "bottom left",
      stagger: 0.2,
    });
    tl.to(screen.children, {
      duration: 0.6,
      scaleY: 0,
      ease: Expo.easeOut,
      transformOrigin: "bottom left",
      stagger: 0.1,
      delay: 0.1,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
      },
    }).delay(1.4);
    TweenMax.to(rightside, 1, {
      delay: 2.4,
      x: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.to(leftback, 1.2, {
      delay: 1.4,
      y: "-50%",
      opacity: 1,
      ease: Expo.easeInOut,
    });
    TweenMax.to(leftside, 1.2, {
      y: "-50%",
      delay: 1.2,
      opacity: 1,
      ease: Expo.easeInOut,
    });
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <React.Fragment>
      <div className="load-container">
        <div className="load-screen2" ref={(el) => (screen = el)}>
          <div className="load-screen2-column"></div>
          <div className="load-screen2-column"></div>
          <div className="load-screen2-column"></div>
          <div className="load-screen2-column"></div>
          <div className="load-screen2-column"></div>
        </div>
      </div>
      <div data-barba="container" className="Messages">
        <div ref={(el) => (body = el)} className="Headd">
          <div className="screen3-background">
            <div className="screen2-layout">
              <div className="screen3-left">
                <div
                  ref={(el) => (leftback = el)}
                  className="screen3-left-background"
                ></div>
                <div className="screen3-message-icon01">
                  <MessageRed className="message-svg" />
                </div>
                <div className="screen3-message-icon02">
                  <MessageRed className="message-svg" />
                </div>
                <div className="screen3-message-icon03">
                  <MessageRed className="message-svg" />
                </div>
                <div
                  ref={(el) => (leftside = el)}
                  className="screen3-left-content"
                >
                  <div className="screen3-pretitle">I har modtaget</div>
                  <div className="screen3-title">{formattedNumber}</div>
                  <div className="screen3-subtitle">beskeder</div>
                </div>
              </div>
              <div className="screen3-right">
                <div
                  ref={(el) => (rightside = el)}
                  className="screen3-right-content"
                >
                  <div className="screen3-heart">
                    <HeartRed />
                  </div>
                  <div className="screen3-right-pretitle">
                    og <span className="highlight">{formattedThanks}</span>{" "}
                    gange har en {data.user} skrevet
                  </div>
                  <div className="screen3-right-title">
                    <ThankYou />
                  </div>
                </div>
              </div>
              <div className="screen3-button">
                <NavLink to="/tasks" className="button">
                  Næste
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
