import React from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";

gsap.registerPlugin(TweenMax, TimelineMax, Power3, Power4);

export default function Tasks() {
  let screen = useRef(null);
  let body = useRef(null);

  const data = JSON.parse(localStorage.getItem("data"));

  var numberFormatter = new Intl.NumberFormat();
  const formattedNumber = numberFormatter.format(data.tasks);

  useEffect(() => {
    var tl = new TimelineMax();
    tl.to(screen, {
      duration: 1.2,
      height: "100%",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 1,
      top: "100%",
      ease: Power3.easeInOut,
      delay: 0.3,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(1);
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <React.Fragment>
      <div className="load-container">
        <div className="load-screen1" ref={(el) => (screen = el)}></div>
      </div>
      <div data-barba="container" className="Tasks">
        <div ref={(el) => (body = el)} className="Headd">
          <div className="screen4-background">
            <div className="screen4-content">
              <div>
                <div className="screen4-title">{formattedNumber}</div>
                <div>opgaver er blevet set</div>
              </div>
            </div>
            <div className="screen4-nextbutton">
              {data.video === "skip" ? (
                <NavLink to="/end" className="button">
                  Næste
                </NavLink>
              ) : (
                <NavLink to="/video" className="button">
                  Næste
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
