import React from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap/dist/gsap";
import { TweenMax, TimelineMax, Power3, Power4, Expo } from "gsap";
import { useRef, useEffect } from "react";

gsap.registerPlugin(TweenMax, TimelineMax, Power3, Power4, Expo);

export default function Patients() {
  let screen = useRef(null);
  let body = useRef(null);
  let type01 = useRef(null);
  let type02 = useRef(null);
  let type03 = useRef(null);
  let type04 = useRef(null);

  const data = JSON.parse(localStorage.getItem("data"));

  var numberFormatter = new Intl.NumberFormat();
  const formattedNumber = numberFormatter.format(data.numUsers);

  useEffect(() => {
    var tl = new TimelineMax();
    tl.to(screen, {
      duration: 1.2,
      height: "100%",
      ease: Power3.easeInOut,
    });
    tl.to(screen, {
      duration: 1,
      top: "100%",
      ease: Power3.easeInOut,
      delay: 0.3,
    });
    tl.set(screen, { left: "-100%" });
    TweenMax.to(body, 0.3, {
      css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
      },
    }).delay(1);
    TweenMax.to(type01, 1, {
      delay: 1.4,
      x: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.to(type02, 1, {
      delay: 1.6,
      x: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.to(type03, 1, {
      delay: 1.8,
      x: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.to(type04, 1, {
      delay: 2,
      x: 0,
      ease: Expo.easeInOut,
    });
    return () => {
      TweenMax.to(body, 1, {
        css: {
          opacity: "0",
          pointerEvents: "none",
        },
      });
    };
  });
  return (
    <React.Fragment>
      <div className="load-container">
        <div className="load-screen1" ref={(el) => (screen = el)}></div>
      </div>
      <div data-barba="container" className="Patients">
        <div ref={(el) => (body = el)} className="Headd">
          <div className="screen2-background">
            <div className="screen2-bg-overlay"></div>
            <div className="screen2-layout">
              <div className="screen2-left">
                <div>
                  <div className="screen2-pretitle">I år har I hjulpet</div>
                  <div className="screen2-title">{formattedNumber}</div>
                  <div className="screen2-subtitle">
                    {data.users} til et bedre forløb ved hjælp af {data.product}
                  </div>
                  <NavLink to="/messages" className="button">
                    Næste
                  </NavLink>
                </div>
              </div>
              <div className="screen2-right">
                <div ref={(el) => (type01 = el)} className="screen2-type-big">
                  {formattedNumber}{" "}
                </div>
                <div
                  ref={(el) => (type02 = el)}
                  className="screen2-type-big outline"
                >
                  {formattedNumber}{" "}
                </div>
                <div ref={(el) => (type03 = el)} className="screen2-type-big">
                  {formattedNumber}{" "}
                </div>
                <div
                  ref={(el) => (type04 = el)}
                  className="screen2-type-big outline"
                >
                  {formattedNumber}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
